import { BrandingProps } from "Context";
import { MAXTheme } from "notes";
import { defaultBrandingStyles } from "./defaultContent";
import _ from "lodash";
import { colors } from "./colors";
export * from "./defaultContent";

export const mergeThemeOverrides = (overrides?: BrandingProps) => {
  const styles: BrandingProps = _.merge({}, defaultBrandingStyles, overrides);

  const updatedThemeObject = {
    ...MAXTheme,
    branding: styles,
    colors: {
      ...MAXTheme.colors,
      ...colors,
    },
    button: {
      ...MAXTheme.button,
      global: {
        ...MAXTheme.button.global,
        borderRadius: "6px",
        minWidth: "184px",
      },
      primary: {
        ...MAXTheme.button.primary,
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#1B0076",
        hover: {
          ...MAXTheme.button.primary.hover,
          backgroundColor: "#1B0076",
        },
      },
      tertiary: {
        ...MAXTheme.button.tertiary,
        paddingTop: "9px",
        paddingBottom: "9px",
        hover: {
          ...MAXTheme.button.tertiary.hover,
          padding: "10px 8px",
        },
      },
    },
    input: {
      ...MAXTheme.input,
      backgroundColor: styles.colors.input.background,
      borderColor: styles.colors.input.border,
      borderRadius: "6px",
      color: styles.colors.input.text,
      placeholder: {
        color: styles.colors.input.placeholder,
      },
      focus: {
        boxShadow: `0 0 4px 0 ${styles.colors.action}`,
      },
    },
    media: {
      ...MAXTheme.media,
      small: "(max-width: 767px)",
      medium: "(min-width: 768px)",
      large: "(min-width: 1280px)",
    },
    typography: mergeTypography(
      {
        placeholderFont: styles.colors.input.placeholderFont,
        inputFont: styles.colors.input.font,
        bodyFont: styles.bodyFont,
        headerFont: styles.headerFont,
      },
      MAXTheme
    ),
  };

  return updatedThemeObject;
};

export const mergeTypography = (fonts, theme) => {
  const { placeholderFont, bodyFont, headerFont, inputFont } = fonts;
  return {
    ...theme.typography,
    h1: {
      ...theme.typography.h1,
      fontFamily: headerFont,
    },
    h2: {
      ...theme.typography.h2,
      fontFamily: headerFont,
    },
    h3: {
      ...theme.typography.h3,
      fontFamily: headerFont,
    },
    h4: {
      ...theme.typography.h4,
      fontFamily: bodyFont,
    },
    label: {
      ...theme.typography.label,
      fontFamily: bodyFont,
    },
    p: {
      ...theme.typography.p,
      fontFamily: bodyFont,
    },
    small: {
      ...theme.typography.small,
      fontFamily: bodyFont,
    },
    subheader: {
      ...theme.typography.subheader,
      fontFamily: bodyFont,
    },
    footnote: {
      ...theme.typography.footnote,
      fontFamily: bodyFont,
    },
    button: {
      ...theme.typography.button,
      fontFamily: bodyFont,
      fontSize: "16px",
      lineHeight: "24px",
    },
    input: {
      ...theme.typography.input,
      fontFamily: inputFont,
    },
    placeholder: {
      ...theme.typography.placeholder,
      fontFamily: placeholderFont,
    },
    link: {
      ...theme.typography.link,
      fontFamily: bodyFont,
    },
  };
};
