import { colors } from "theme/colors";
import { alpha } from "Utils";

export const themeOverrides = (theme) => ({
  ...theme,
  fonts: {
    ...theme.fonts,
    primary: "'Poppins', sans-serif",
    secondary: "'Poppins', sans-serif",
  },
  button: {
    ...theme.button,
    global: {
      ...theme.button.global,
      borderRadius: "6px",
      minWidth: "184px",
    },
    disabled: {
      ...theme.button.disabled,
      padding: "10px 12px",
    },
    primary: {
      ...theme.button.primary,
      padding: "10px 12px",
      backgroundColor: "#1B0076",
      hover: {
        ...theme.button.primary.hover,
        backgroundColor: "#1B0076",
      },
    },
    secondary: {
      ...theme.button.secondary,
      color: colors.navy[100],
      borderColor: colors.navy[100],
      borderWidth: "1px",
      padding: "9px 12px",
      hover: {
        ...theme.button.secondary.hover,
        backgroundColor: colors.navy[10],
        borderColor: colors.navy[100],
        borderWidth: "1px",
        boxShadow: "none",
        color: colors.navy[100],
        padding: "9px 12px",
      },
    },
    destroy: {
      ...theme.button.destroy,
      borderWidth: 0,
      padding: "10px 12px",
      color: colors.gray[0],
      backgroundColor: colors.red[100],
      hover: {
        ...theme.button.tertiary.hover,
        backgroundColor: colors.red[100],
        color: colors.gray[0],
        padding: "10px 12px",
      },
    },
    tertiary: {
      ...theme.button.tertiary,
      padding: "9px 12px",
      color: colors.black[80],
      borderColor: colors.black[20],
      backgroundColor: "transparent",
      hover: {
        ...theme.button.tertiary.hover,
        padding: "9px 12px",
        backgroundColor: colors.gray[3],
        borderColor: colors.black[20],
        borderWidth: "1px",
        color: colors.black[80],
        boxShadow: "none",
      },
    },
    link: {
      ...theme.link,
      backgroundColor: "transparent",
      borderWidth: 0,
      color: colors.black[60],
      padding: "10px 12px",
      hover: {
        ...theme.button.link.hover,
        padding: "10px 12px",
        backgroundColor: colors.gray[2],
        color: colors.black[80],
        boxShadow: "none",
        textDecoration: "none",
      },
    },
    soft: {
      color: colors.navy[100],
      backgroundColor: alpha(colors.navy[60], 0.16),
      borderWidth: 0,
      minWidth: 0,
      padding: "6px 12px",
      whiteSpace: "nowrap",
      hover: {
        color: colors.navy[100],
        backgroundColor: alpha(colors.navy[40], 0.32),
        boxShadow: "none",
      },
      disabled: {
        color: colors.black[40],
        backgroundColor: alpha(colors.black[20], 0.24),
        padding: "6px 12px",
      },
    },
  },
  input: {
    ...theme.input,
    borderColor: colors.black[20],
    focus: {
      borderColor: colors.black[20],
      boxShadow: `0px 0px 8px rgba(0, 0, 0, .24)`,
    },
  },
  media: {
    ...theme.media,
    small: "(max-width: 600px)",
    medium: "(min-width: 601px)",
    large: "(min-width: 1280px)",
  },
});
