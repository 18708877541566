import logoWhite from "Images/LogoWhite.png";
import logoDark from "Images/LogoBlack.png";
import logoWhiteLarge from "Images/LogoWhiteLarge.png";
import { BrandingProps } from "Context";

const assetPath = process.env.REACT_APP_ASSETS_PATH;

export const defaultBrandingContent: BrandingProps = {
  brand: "SET.Live",
  logos: {
    landing: {
      path: logoWhiteLarge,
    },
    event: {
      path: logoWhite,
    },
    dark: {
      path: logoDark,
    },
  },
  googleFontUrl:
    "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap",
  landing: {
    title: "JOIN YOUR SHOW NOW",
    button: "Use Current Location",
    buttonLoading: "Finding Your Show",
    blocks: [
      {
        image: `${assetPath}/9sl3TR2NR9kFBDxMQeImv0.jpg`,
        title: "WHAT IS SET.LIVE?",
        body: "SET.Live is interactive live music. Request a song or just show your support to artists!",
        button: "Find a Show!",
        link: "scroll-top",
      },
      {
        image: `${assetPath}/yQvFS4XAZ1QKNMLA6uS2OX.jpg`,
        title: "ARE YOU AN ARTIST?",
        body: "Let us know if you'd like to use SET.Live at your show.",
        button: "Get Started!",
        link: "https://about.set.live",
      },
    ],
  },
  lobby: {
    joinButton: "Join Show",
    cancelButton: "Choose A Different Show",
    // splashImage: "https://stage.set.live/assets/a1fomT0clihkhkYZIednsK.png",
    disableAvatar: false,
    disableText: false,
  },
  footer: {
    copyright: `&copy; 2023 SET.Live&trade;`,
    termsOfUseUrl: "/terms",
    privacyPolicyUrl: "/privacy",
  },
};

export const defaultBrandingStyles: BrandingProps = {
  headerFont: "Poppins, sans-serif",
  bodyFont: "Poppins, sans-serif",
  logos: {
    landing: {
      width: "100px",
    },
    event: {
      width: "75px",
    },
    dark: {
      width: "63px",
    },
  },
  colors: {
    action: "#1B0076",
    actionLabel: "#ffffff",
    actionSecondary: "#ffffff",
    actionSecondaryLabel: "#1B0076",
    actionSecondaryBorder: "#1B0076",
    actionComplete: "#E6E9EB",
    input: {
      background: "#ffffff",
      border: "#596266",
      text: "#222222",
      font: "Arial, sans-serif",
      placeholder: "#596266",
      placeholderFont: "Poppins, sans-serif",
    },
  },
  landing: {
    colors: {
      button: "rgba(255,255,255,0)",
      buttonBorder: "1px solid #ffffff",
      buttonText: "#ffffff",
      headerActions: "#ffffff",
      title: "#fff",
      page: "linear-gradient(70deg, #E71E3D 30%, #1B0076 100%)",
      blocks: {
        title: "#222",
        body: "#333",
        background: "#fff",
        button: "#1B0076",
      },
      copyright: "#ffffffd4",
      footerLinks: "#ffffff",
    },
  },
  lobby: {
    colors: {
      header: "linear-gradient(10deg, #E71E3D 0%, #1B0076 100%)",
      headerActions: "#ffffff",
      page: "#F7FAFC",
      label: "#A6AEB2",
      artist: "#000000",
      venue: "#333333",
      date: "#333333",
      legal: "#666666",
      copyright: "#999999",
      footerLinks: "#666666",
    },
  },
  event: {
    bannerGradientOffset: 60,
    bannerTextDropShadow: "none",
    disableImageBlur: false,
    colors: {
      header: "linear-gradient(0deg, #0000 0%, #00000085 100%)",
      headerActions: "#ffffff",
      page: "#000000",
      artist: "#ffffff",
      venue: "#ffffff",
      date: "#F7FAFC",
      divider: "#EC4B64",
      title: "#F1788B",
      body: "#ffffff",
      description: "#a6aeb2",
      caption: "#a6aeb2",
      listTitle: "#ffffff",
      listSubtitle: "#a6aeb2",
      listDivider: "#59626650",
      progressBar: "linear-gradient(225deg, #b4ec51 0%, #429321 100%)",
      progressBarBackground: "#59626650",
      tabs: {
        activeBackground: "#f7f7f5",
        activeText: "#222222",
        inactiveBackground: "#333333",
        inactiveText: "#f7f7f5",
      },
    },
  },
};
